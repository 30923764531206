import React, { useContext } from 'react';

import * as classes from './projects.module.scss';

import { DaylightContext } from '../contexts/daylightContext';
import { RepoExtraContext } from '../contexts/repoExtraContext';

import SEO from '../components/SEO';
import RepoCard from '../components/shared/repoCard/RepoCard';
import RiverSection from '../components/riverSection/RiverSection';

function ProjectsPage(props) {
	const { daylight } = useContext(DaylightContext);
	const { extraRepos } = useContext(RepoExtraContext);

	return (
		<>
			<div
				className={` ${daylight ? classes.reposWrap : classes.reposWrapDark}`}
			>
				<SEO title="Projects" />

				<main id="main">
					<h1
						style={{
							color: daylight ? 'var(--nightSkyDarker)' : 'var(--white)',
						}}
					>
						Projects
					</h1>
					<div className={classes.repoGrid}>
						{extraRepos &&
							extraRepos.length &&
							extraRepos.map((repo) => (
								<RepoCard key={repo.name} repo={repo} single={false} />
							))}
					</div>
				</main>
			</div>
			<div style={{ overflow: 'hidden' }}>
				<RiverSection sail={true} />
			</div>
		</>
	);
}
export default ProjectsPage;
