import React, { useMemo } from 'react';
import * as classes from './Languages.module.scss';

const Languages = ({ languages }) => {
	//  Js, css & html colors look stupid cause marie.ie also has a yellow, red & purple. Switch githubs colors for marie.ie colors. Also calc the language as a percentage for convenience.
	const languagesWithColors = useMemo(
		() =>
			languages.edges.map((language) => {
				language.asPercentage = (
					(language.size * 100) /
					languages.totalSize
				).toFixed(1);
				if (language.node.name === 'JavaScript') {
					language.node.color = 'var(--yellow)';
				}
				if (language.node.name === 'CSS') {
					language.node.color = 'var(--purple)';
				}
				if (language.node.name === 'HTML') {
					language.node.color = 'var(--red)';
				}
				return language;
			}),
		[languages.edges, languages.totalSize]
	);

	return (
		<>
			<div className={classes.languagePercentsWrap} aria-hidden="true">
				{languagesWithColors.map((language) => {
					return (
						<span
							key={language.node.name}
							style={{
								width: `${language.asPercentage}%`,
								background: language.node.color,
							}}
						>
							{' '}
						</span>
					);
				})}
			</div>
			<dl className={classes.languagesWrap}>
				{languagesWithColors.map((language) => (
					<div className={classes.languageInfo} key={language.node.name}>
						<svg
							style={{ fill: language.node.color }}
							viewBox="0 0 16 16"
							version="1.1"
							width="16"
							height="16"
							aria-hidden="true"
						>
							<path d="M8 4a4 4 0 100 8 4 4 0 000-8z"></path>
						</svg>
						<dt>{language.node.name}</dt>
						<dd key={language.node.name}>
							({language.asPercentage}%)
						</dd>
					</div>
				))}
			</dl>
		</>
	);
};

export default Languages;
