import React from 'react';

export default function SelectStop({
	detailsOpen,
	stops,
	selectedStopId,
	classes,
	setSelectedStopId,
	setSelectedStopName,
}) {
	function onChangeField(e) {
		const id = e.target.value;
		const stop = stops.find((s) => s.stop_id === id);

		if (id && stop) {
			setSelectedStopId(id);
			setSelectedStopName(stop.stop_name);
		}
		return;
	}

	return (
		<div className={classes.stopDropdownWrap}>
			{detailsOpen && stops ? (
				<fieldset>
					<label htmlFor="stop">Select stop:</label>
					<select
						id="stop"
						onChange={onChangeField}
						className={classes.testSelect}
						value={selectedStopId}
					>
						{stops.map((s, i) => (
							<option key={s.stop_id} id={s.stop_id} value={s.stop_id}>
								{s.stop_name.substring(0, 10)}
								{s.stop_name.substring(
									s.stop_name.length - 7,
									s.stop_name.length
								)}
							</option>
						))}
					</select>
				</fieldset>
			) : null}
		</div>
	);
}
