export const niceMinutes = (r) => {
	let output = '?';

	// There's 5 scenarios.
	// 1. City Direct never has realtime
	if (r.rt === null) {
		output = '-';
	}

	// 2. Delay is 0
	if (r.rt !== null && r.departure_delay.toString() === '0') {
		output = '0 min';
	}

	// 3. Delay is > 0
	if (r.rt !== null && r.departure_delay && parseInt(r.departure_delay) > 0) {
		const delay = parseInt(r.departure_delay) / 60;
		const min = delay && delay > 1 ? 'mins' : delay && delay <= 1 ? 'min' : '';
		const ans = `+ ${delay} ${min}`;
		output = ans;
	}
	// 4. Delay is < 0 Early
	if (r.rt !== null && r.departure_delay && parseInt(r.departure_delay) < 0) {
		const delay = Math.abs(parseInt(r.departure_delay)) / 60;
		const min = delay && delay > 1 ? 'mins' : delay && delay <= 1 ? 'min' : '';
		const ans = `- ${delay} ${min}`;
		output = ans;
	}

	// 5. Either something is wrong or - GTFS-R StopTime.departure only HAS to list .delay or .time - so if StopTime.departure.delay is omitted in the feed or set to 'null' departure_delay will be null, output will still be '?'.
	return output;
};

export const fetchBusData = async (stopId = '8460B5230001') => {
	// export async function fetchBusData(stopId = '8460B5230201') {
	//  CILL ARD
	const url = `https://galway-bus.apis.ie/api/realtime/gstoptimes/${stopId}`;
	try {
		const response = await fetch(url);
		const ans = await response.json();
		const noRealTimeRoutes = ['410', '411', '412', '414'];

		// filter out stopws with rt info and for each route only keep the first 2 results
		const now = new Date().toLocaleTimeString('en-IE').substring(0, 5);
		const filteredForRT = ans.results.map((r) => {
			const output = r.g_stop_times.filter((st) => {
				// Some routes never have realtime, don't exclude
				if (noRealTimeRoutes.includes(st.route_short_name)) {
					return st.departure_time.substring(0, 5) > now;
				}

				// Buses tend to stay on the feed for a while, the first two will probably have recently departed. So we filter first for gStopTimes with realtime & a departure_time in the future
				return st.rt !== null && st.departure_time.substring(0, 5) > now;
			});

			if (output.length > 2) {
				const withTwo = output.slice(0, 2);

				return withTwo;
			}
			return output;
		});

		return filteredForRT;
	} catch (e) {
		return e;
	}
};

export const fetchStopsList = async () => {
	const url = `https://galway-bus.apis.ie/api/gstop/bystopid`;
	const response = await fetch(url);
	const ans = await response.json();

	return ans.results.stops;
};
