import React, { useContext } from 'react';

import * as classes from './repoCard.module.scss';

import { DaylightContext } from '../../../contexts/daylightContext';

import TransitionFade from '../../transitions/TransitionFade';
import Languages from '../languages/Languages';
import Technologies from '../technologies/Technologies';
import Video from '../video/Video';
import ExternalLink from '../../svg/external/ExternalLink';
import BusWidget from '../../busWidget/BusWidget';
import WeatherWidget from '../../weatherWidget/WeatherWidget';

const RepoCard = ({ repo, single }) => {
	const { daylight } = useContext(DaylightContext);

	return repo ? (
		<div
			className={`${classes.repoItem} ${
				daylight ? classes.repoItemLight : classes.repoItemDark
			} ${single && classes.single}`}
		>
			{repo.video ? <Video daylight={daylight} repo={repo} /> : null}
			{repo.busWidget ? <BusWidget /> : null}
			{repo.weatherWidget ? (
				<WeatherWidget dark={daylight ? false : true} />
			) : null}

			<div className={classes.textWrap}>
				<div className={classes.cardMiddle}>
					{!single && (
						<div className={classes.cardBox}>
							<TransitionFade
								url={`/repo/${repo.name}`}
								effect="fadeUp"
								length={0.5}
							>
								<h2 className="h4">{repo.displayName}</h2>
							</TransitionFade>
							{!repo.localDescription && <p>{repo.description}</p>}
							{repo.localDescription && <repo.localDescription />}
						</div>
					)}

					<div className={classes.cardBox}>
						<h3 className="h5">Languages</h3>
						<Languages languages={repo.languages} />
					</div>

					<div className={classes.cardBox}>
						<h3 className="h5">Technologies</h3>
						<Technologies daylight={daylight} tech={repo.tech} />
					</div>
				</div>

				<div className={classes.linksWrap}>
					{repo.url && repo.showGitUrl ? (
						<a href={repo.url} title="Go to github (external link)">
							Code (GitHub){' '}
							<span>
								<ExternalLink />
							</span>
						</a>
					) : null}
					{repo.liveUrl && (
						<a href={repo.liveUrl} title="Go to live site (external link)">
							Go to Website
							<span>
								<ExternalLink />
							</span>
						</a>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export default RepoCard;
