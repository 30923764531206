import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as classes from './busWidget.module.scss';

import { fetchBusData, fetchStopsList, niceMinutes } from './bus-widget-utils';

import SelectStop from './SelectStop';

const FIVE_MINS = 300000;

function BusWidget({ style = {} }) {
	const [busData, setBusData] = useState(null);
	const [stops, setStops] = useState(null);
	const [selectedStopId, setSelectedStopId] = useState('8460B5230001');
	const [selectedStopName, setSelectedStopName] = useState('CILL ARD');
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [time, setTime] = useState(new Date().toLocaleTimeString('en-IE'));
	const [glow, setGlow] = useState(true);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	// Load data
	useEffect(() => {
		async function getData() {
			setLoading(true);
			const ans = await fetchBusData(selectedStopId);

			if (ans && !ans.error) {
				setBusData(ans);
				setLoading(false);
			} else {
				setError(true);
				setLoading(false);
			}
		}
		getData();
	}, [selectedStopId]);

	// Load stops list for dropdown
	useEffect(() => {
		async function getStops() {
			try {
				const ans = await fetchStopsList();
				if (ans) {
					const sorted = ans.sort((a, b) =>
						a.stop_name.localeCompare(b.stop_name)
					);
					setStops(sorted);
				}
			} catch (e) {
				console.log('error stops', e);
			}
		}
		getStops();
	}, []);

	// do clock
	useEffect(() => {
		const clock = setInterval(
			() => setTime(new Date().toLocaleTimeString('en-IE')),
			1000
		);
		return () => {
			clearInterval(clock);
		};
	});

	const savedCallback = useRef();

	async function getDataAgain() {
		try {
			setLoading(true);
			const ans = await fetchBusData(selectedStopId);
			if (ans) {
				setBusData(ans);
				setLoading(false);
			}
		} catch (e) {
			setError(true);
			setLoading(false);
			console.log('error obs', e);
		}
	}

	useEffect(() => {
		savedCallback.current = getDataAgain;
	});

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		let updateDataInterval = setInterval(tick, FIVE_MINS);
		return () => clearInterval(updateDataInterval);
	}, []);

	// close details when a new stop is selected
	useEffect(() => {
		setDetailsOpen(false);
	}, [selectedStopId]);

	// Because of the clock the 'departure board' renders every second - use useMemo so it only figures out the dynamic part when busData changes.
	const niceBusDataMemo = useMemo(() => {
		return (
			busData?.length &&
			busData.map((route, i) =>
				route?.length
					? route.map((r) => (
							<tr key={r.trip_id}>
								<td>{r.route_short_name}</td>
								<td>{r.last_stop_name.substring(0, 10)}</td>
								<td>{r.departure_time}</td>
								<td>{niceMinutes(r)}</td>
							</tr>
					  ))
					: null
			)
		);
	}, [busData]);

	return (
		<div className={classes.busWidget} style={style}>
			<button
				className={`${classes.refreshBtn} button`}
				onClick={async () => await getDataAgain()}
			>
				<span className={classes.srOnly}>Refresh widget</span>
				<img
					alt="refresh"
					width="18"
					height="18"
					src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='18' height='18' %3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath fill='%23ffffff' d='M464 16c-17.67 0-32 14.31-32 32v74.09C392.1 66.52 327.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.89 5.5 34.88-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c50.5 0 96.26 24.55 124.4 64H336c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32V48C496 30.31 481.7 16 464 16zM441.8 289.6c-16.92-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-50.5 0-96.25-24.55-124.4-64H176c17.67 0 32-14.31 32-32s-14.33-32-32-32h-128c-17.67 0-32 14.31-32 32v144c0 17.69 14.33 32 32 32s32-14.31 32-32v-74.0 9C119.9 445.5 184.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z'/%3E%3C/svg%3E"
				/>
			</button>
			<button
				className={`${classes.lightBulb} button`}
				onClick={() => setGlow(!glow)}
			>
				<span className={classes.srOnly}>Toggle the glowing text.</span>
				{glow ? (
					<img
						alt="A lightbulb"
						width="18"
						height="18"
						src="data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg' fill='%23ffa500' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Pro 6.1.1 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons  Inc. --%3E%3Cpath d='M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7L272 415.1h-160L112.1 454.3zM191.4 .0132C89.44 .3257 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8c16.53 18.84 42.34 58.23 52.22 91.45c.0313 .25 .0938 .5166 .125 .7823h160.2c.0313-.2656 .0938-.5166 .125-.7823c9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1C368 78.61 288.9-.2837 191.4 .0132zM192 96.01c-44.13 0-80 35.89-80 79.1C112 184.8 104.8 192 96 192S80 184.8 80 176c0-61.76 50.25-111.1 112-111.1c8.844 0 16 7.159 16 16S200.8 96.01 192 96.01z'/%3E%3C/svg%3E"
					/>
				) : (
					<img
						alt="A lightbulb"
						width="18"
						height="18"
						src="data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Pro 6.1.1 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons  Inc. --%3E%3Cpath d='M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7L272 415.1h-160L112.1 454.3zM191.4 .0132C89.44 .3257 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8c16.53 18.84 42.34 58.23 52.22 91.45c.0313 .25 .0938 .5166 .125 .7823h160.2c.0313-.2656 .0938-.5166 .125-.7823c9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1C368 78.61 288.9-.2837 191.4 .0132zM192 96.01c-44.13 0-80 35.89-80 79.1C112 184.8 104.8 192 96 192S80 184.8 80 176c0-61.76 50.25-111.1 112-111.1c8.844 0 16 7.159 16 16S200.8 96.01 192 96.01z'/%3E%3C/svg%3E"
					/>
				)}
			</button>

			<table
				className={`${glow ? classes.glow : classes.noGlow} ${
					classes.busWidgetTable
				}`}
			>
				<caption>
					LIVE Departures -
					<details
						open={detailsOpen}
						onToggle={(e) => setDetailsOpen(e.target.open)}
						className={`${
							detailsOpen ? classes.detailsOpen : classes.detailsClosed
						}`}
					>
						<summary>{selectedStopName.toUpperCase()}</summary>
						<SelectStop
							classes={classes}
							detailsOpen={detailsOpen}
							selectedStopId={selectedStopId}
							stops={stops}
							setSelectedStopId={setSelectedStopId}
							setSelectedStopName={setSelectedStopName}
						/>
					</details>
					{time}
				</caption>
				<thead>
					<tr>
						<td>Route</td>
						<td>Destination</td>
						<td>Scheduled</td>
						<td>Realtime</td>
					</tr>
				</thead>
				<tbody>
					{!loading && !error && busData?.length ? (
						niceBusDataMemo
					) : !error && loading ? (
						<tr className={glow ? classes.glow : classes.noGlow}>
							<td colSpan={4} text-align="center">
								Loading...
							</td>
						</tr>
					) : (
						<tr className={glow ? classes.redGlow : classes.noGlow}>
							<td colSpan={4} text-align="center">
								Out of Service
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<span>Data from TFI's Static GTFS & GTFS-R feed via Galway Bus API.</span>
		</div>
	);
}

export default BusWidget;
