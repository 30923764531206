import React from 'react';
import * as classes from './technologies.module.scss';

const Technologies = ({ daylight, tech }) => {
	return (
		<div className={classes.techWrap}>
			<ul
				className={`${classes.tech} ${daylight ? classes.day : classes.night}`}
			>
				{tech.map((t) => (
					<li key={t}>{t}</li>
				))}
			</ul>
		</div>
	);
};

export default Technologies;
